import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import Card from '../components/Card'
import CardList from '../components/CardList'
import styled from '@emotion/styled'
import { ElementColors } from '../styles/styleVariables'
import { graphql } from 'gatsby'
import { map } from 'lodash/fp'

const Hero = styled.div`
  width: 100vw;
  height: 530px;

  background-image: url('https://scontent-arn2-1.xx.fbcdn.net/v/t1.0-9/106701546_1074664482935462_7116476318732607349_n.jpg?_nc_cat=110&ccb=2&_nc_sid=8bfeb9&_nc_ohc=ZjeJXCkypTIAX-3Yb4K&_nc_ht=scontent-arn2-1.xx&oh=f84416142f1c789337030c5a586134e3&oe=6038A4A1');
  background-position: center;
  /* Make the background image cover the area of the <div>, and clip the excess */
  background-size: cover;
`

const TitleWrapper = styled.div`
  max-width: 1050px;
  margin: auto;
  padding: 80px 0 0 1.5em;
`

const Title = styled.h1`
  display: inline-block;
  color: ${ElementColors.primaryText};
  background-color: black;
  font-size: 3em;
  font-weight: 600;
`

const TextBoxTitle = styled.h2`
  margin-bottom: 12px;
  color: ${ElementColors.secondaryText};
  text-align: center;
  font-size: 36px;
  font-weight: 500;
`

const TextBox = styled.p`
  max-width: 500px;
  margin: 0 auto 48px;
  color: ${ElementColors.secondaryText};
  font-size: 16px;
  text-align: center;
`

const Contact = ({ data }) => {
  console.log({ data })
  const projects = data?.allContentfulPost?.nodes
  return (
    <Layout>
      <SEO
        title="AN Takplat"
        description="AN Takplat AB - Taklaggare i Stockholm och Nykoping"
      />
      <Hero>
        <TitleWrapper>
          <Title>Din takläggare i Stockholm och Nyköping.</Title>
        </TitleWrapper>
      </Hero>
      <Container>
        <TextBoxTitle>Tak i plåt, plåt i tak</TextBoxTitle>
        <TextBox>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris in
          aliquam sem fringilla ut morbi tincidunt augue.
        </TextBox>
        <CardList>
          {map(project => {
            return (
              <Card
                title={project.title}
                slug={project.slug}
                body={project.body}
                heroImage={project.heroImage}
                publishDate={project.publishDate}
              />
            )
          }, projects)}
        </CardList>

        <TextBoxTitle>Därför ska ANTak lägga ditt tak</TextBoxTitle>
        <TextBox>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Mauris in
          aliquam sem fringilla ut morbi tincidunt augue.
        </TextBox>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulPost(limit: 3) {
      nodes {
        title
        slug
        body {
          body
        }
        heroImage {
          description
          title
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
            tracedSVG
          }
        }
      }
    }
  }
`

export default Contact
